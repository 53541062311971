import React from "react";
import PostCard from "./PostCard";
import { injectIntl } from "gatsby-plugin-react-intl";

const PostList = ({ posts, intl }) => {
	return (
		<section>
			<div className="blogPostList">
				{posts.map(({ node }, index) => {
					return <PostCard key={index} post={node} />;
				})}
			</div>
		</section>
	);
};

export default injectIntl(PostList);
