import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage ,Link } from "gatsby-plugin-react-intl";

const PostCard = ({ post }) => {
	const { title, date, author, linkTo } = post.frontmatter;
	const image = post.frontmatter.image.childImageSharp.gatsbyImageData;

	return (
        <article>
			<div className="blogCard">
				<div className="boxBlogCardImage">
					<GatsbyImage image={image} className="blogCardImage" alt="blogImage" loading="eager" />
				</div>
				<div className="boxBlogCardText">
					<div className="boxBlogCardTextWrapper">
						<p className="blogCardsTitles">{title}</p>
						<div className="boxBlogCardDateText">
						    <span className="blogCardAuthor">{author} </span>
						    <span className="blogCardDate">{date}</span>
					    </div>
						<p className="blogTextSmall">{post.excerpt}</p>
					</div>
					<div className="boxBlogCardButtonWrapper">
						<Link to={linkTo}>
							<button type="button" className="blogReadMoreButton">
							<FormattedMessage id="news.learn" />
							</button>
						</Link>
					</div>
				</div>
				
			</div>
		</article>
    );
};

export default PostCard;
