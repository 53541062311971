import React, { useEffect, useRef } from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import PostList from "../components/blog/PostList";
import { graphql, useStaticQuery } from "gatsby";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import { TiArrowSortedDown } from "react-icons/ti";
import scrollTo from "gatsby-plugin-smoothscroll";
import FadeIn from "../components/animations/FadeIn";
import { gsap } from "gsap";

const News = ({ intl }) => {
	const data = useStaticQuery(graphql`{
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    totalCount
    edges {
      node {
        frontmatter {
          title
          slug
          linkTo
          date(formatString: "Do MMMM, YYYY")
          author
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        excerpt(pruneLength: 180)
      }
    }
  }
}
`);
	const posts = data.allMdx.edges;

	let newsHomeText = useRef(null);
	let newsHomeButton = useRef(null);

	
		
		useEffect(() => {
			gsap.fromTo(newsHomeText.current, {opacity: 0}, {duration: 3, opacity: 1, delay: 1})
			gsap.fromTo(newsHomeButton.current, {opacity: 0}, {duration: 3, opacity: 1, delay: 1})

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
	return (
		<>
			<SEO title={intl.formatMessage({ id: "navbar.news" })} />
			<div className="newsPage">
				<div className="newsSectionHome">
					<div className="boxNewsSide">
						<FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
					</div>

					<div className="boxNewsHome" >
							<div className="newsTitleDiv" ref={newsHomeText}>
								<p className="newsTitleText1">{intl.formatMessage({ id: "news.tehnomatiknews1" })}</p>
								<p className="newsTitleText2">{intl.formatMessage({ id: "news.tehnomatiknews2" })}</p>
							</div>
							<div className="newsPageLearnSection" ref={newsHomeButton}>
								<svg
									width="1"
								    viewBox="0 0 2 135"
									fill="none"
									alt="tehnomatikSvg"
								>
									<line
										x1="0.499986"
										y1="0.996269"
										x2="1.49999"
										y2="134.996"
										stroke="#fff"
									/>
								</svg>
								<button
									className="newslearnMoreButton"
									onClick={() => scrollTo("#newsCardsSectionId")}
								>
									<TiArrowSortedDown size={28} />
								</button>
							</div>
					</div>

					<div className="boxNewsEmptyRight"></div>
					<div className="boxNewsBottomText">
						<BottomFactory />
					</div>
				</div>

				<div className="newsCardsSection" id="newsCardsSectionId">
					<PostList posts={posts} />
				</div>
				<div className="blogBottomTitle">
				<p>Tehnomatik Blog</p>
				</div>
			</div>
		</>
	);
};

export default injectIntl(News);
